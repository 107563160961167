<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" @importContacts="importContacts" :flat="false" :url="apiUrl"/>
      <importContactComponent v-if="displayUpload" @closeModal="closeModal" :displayUpload="displayUpload" :entityId="entityId" />
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2';
import importContactComponent from '@/components/importContactComponent';

export default {
  name: "EntityDetailContact",
  components: {
    DatatableV2,
    importContactComponent
  },
  data() {
    return {
      apiUrl:"/entity/" + this.$route.params.entity_id + "/contactList",
      displayUpload: false,
      entityId: null
    };
  },
  methods: {
    importContacts() {
      this.entityId = this.$route.params.entity_id;
      this.displayUpload = true;
    },
    closeModal(){
      this.displayUpload = false;
    }
  },
};
</script>
