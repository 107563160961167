<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :key="datatableKey" :displayTitle="false" :datatableId="'burp'" :flat="false" :url="apiUrl" ref="blacklistListDatatable" @importFile="importFile" @exportBlacklist="displayExport = true"/>
            <importBlacklistComponent @closeModal="closeModal" :displayUpload="displayUpload"/>
            <ExportBlacklist @closeModal="displayExport = false" :displayExport="displayExport"/>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2';
import importBlacklistComponent from '@/components/importBlacklistComponent';
import ExportBlacklist from '@/components/exportBlacklist.vue';

export default {
    name: "BlacklistList",
    components: { DatatableV2, importBlacklistComponent, ExportBlacklist },
    data() {
        return {
            apiUrl: "/blacklist/list",
            displayUpload: false,
            datatableKey: 0,
            displayExport: false
        }
    },
    methods: {
        importFile() {
            this.displayUpload = true;
        },
        closeModal(reload = false) {
            this.displayUpload = false;
            this.datatableKey++;
        }
    },
}
</script>