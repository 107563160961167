<template>
    <v-container fluid class="pa-6">
        <v-row>
        <v-col xs="12" sm="12" lg="4">
            <v-card>
                <v-card-title>
                    <h4 class="icon-title" style="width:100%; font-size:24px; border-bottom: none;">
                        {{brand.libelle}}
                        <v-btn @click="getForm('/brand/getForm?id='+brand.id)" text class="square" v-if="brand.account_id != 0">
                            <v-icon small>$pencil_l</v-icon>
                        </v-btn>
                    </h4>
                    <v-chip class="mt-3" v-if="brand && brand.type" style="text-transform:uppercase;" outlined :color="brand.type.color">{{brand.type.label}}</v-chip>
                    <v-chip class="mt-3 ml-2" v-if="brand && brand.sector" style="text-transform:uppercase;" outlined color="primary">{{brand.sector.name}}</v-chip>
                </v-card-title>
                <v-card-text>
                    <h2 style="text-transform:none; align-items:center;" class="icon-title">Noms alternatifs <v-btn class="square" color="primary" text @click="openDialog(false, false)"><v-icon small>$plus_l</v-icon></v-btn></h2>
                    <v-list dense v-if="brand.alternates && brand.alternates.length > 0">
                        <v-list-item class="lightgrey mb-1" v-for="(alternate, index) in brand.alternates" :key="'alternate_'+index">
                            <div class="alternateItem">
                                <span>{{alternate}}</span>
                                <span>
                                    <v-btn class="square" @click="openDialog(true, index)" text><v-icon small>$pencil_l</v-icon></v-btn>
                                    <v-btn class="square" text color="error" @click="confirmDeleteItem(index)" ><v-icon small>$close</v-icon></v-btn>
                                </span>
                            </div>
                        </v-list-item>
                    </v-list>
                    <v-alert v-else class="mt-3" border="left" color="info" text>
                        {{$t('emptyAlternateMessage')}}
                    </v-alert>
                </v-card-text>
            </v-card>
            <v-dialog width="500" v-model="dialogAlternate">
                <v-card>
                    <v-toolbar tile flat dark  class="bg-gradient">
                        <v-toolbar-title>{{$t('addAlternateTitle')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined depressed small class="mr-2" @click="closeDialogAlternate">{{$t('cancelLabel')}}</v-btn>
                        <v-btn outlined depressed small class="mr-2" @click="recordNewAlternate" v-if="!editNames">{{$t('recordLabel')}}</v-btn>
                        <v-btn outlined depressed small class="mr-2" @click="editAlternate(editIndex)" v-else>{{$t('recordLabel')}}</v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field depressed solo flat filled dense hide-details :label="$t('alternateNameLabel')" class="mt-4 input-form" v-model="newAlternateName"></v-text-field>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog width="600" v-model="dialogConfirmDelete">
                <v-card>
                    <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                        <v-toolbar-title>{{$t('eraseLabel')}} : {{brand.alternates[deleteIndex]}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined depressed small class="mr-2" @click="closeDialogAlternate">{{$t('cancelLabel')}}</v-btn>
                        <v-btn outlined depressed small class="mr-2" @click="deleteItem">{{$t('confirmLabel')}}</v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <div class="mt-3">
                            {{$t('confirmEraseBrandAlternateLabel')}}
                        </div>
                    </v-card-text>                    
                </v-card>
            </v-dialog>
        </v-col>
        <v-col xs="12" sm="12" lg="8" class="editStyle">
            <styleBrandComponent />
        </v-col>
        <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        />
        </v-row>
    </v-container>
</template>
<script>
import styleBrandComponent from '@/components/styleBrandComponent'
import GenericDataService from '@/services/GenericDataService'
import refreshData from "@/mixins/mixins";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";

export default {
    name:"BrandDashboard",
    components: {styleBrandComponent, FormDialogComponent},
    mixins: [refreshData, getForm],
    data() {
        return {
            dialogAlternate:false,
            dialogConfirmDelete:false,
            publicPath: process.env.BASE_URL,
            newAlternateName: "",
            apiUrl: "/brand/"+this.$route.params.brand_id+"/get",
            mutation: "brand/setBrandData",
            editNames: false,
            editIndex: 0,
            deleteIndex:null,
            form:null,
        }
    },
    computed: {
        brand(){
            return this.$store.state.brand.brand
        }
    },
    methods: {
        openDialog(edit, index){
            if(edit){
                this.editNames = true
                this.editIndex = index
                this.newAlternateName = this.brand.alternates[index]
            }
            this.dialogAlternate = true
        },
        closeDialogAlternate(){
            this.newAlternateName=""
            this.editNames = false
            this.editIndex = 0
            this.dialogAlternate = false
        },
        recordNewAlternate(){
            let brandToUpdate = this._.cloneDeep(this.brand);
            brandToUpdate.alternates.push(this.newAlternateName);
            this.recordData(brandToUpdate)
        },
        editAlternate(index){
            let brandToUpdate = this._.cloneDeep(this.brand);
            brandToUpdate.alternates[index] = this.newAlternateName
            this.recordData(brandToUpdate)
        },
        confirmDeleteItem(index){
            this.deleteIndex = index
            this.dialogConfirmDelete = true
        },
        deleteItem(){
            let brandToUpdate = this._.cloneDeep(this.brand);
            brandToUpdate.alternates.splice(this.deleteIndex, 1)
            this.recordData(brandToUpdate)
        },
        recordData(data){
            GenericDataService.postData('/brand/set', data).then((response) => {
                this.refreshData()
                this.newAlternateName=""
                this.editNames = false
                this.editIndex = 0
                this.deleteIndex = 0
                this.dialogAlternate = false
                this.dialogConfirmDelete = false
                this.$store.dispatch('ADD_SNACK', {
                    message : this.$t('successActionConfirmed'),
                }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                })
            })
        }
    },
}
</script>
<style lang="scss">
    .alternateItem {
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
</style>