<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :datatableId="'burp'" @importContacts="importContacts" @launchSegmentation="launchSegmentation" @openStats="openStats" @openLibraryDialog="openLibraryDialog" :flat="false" :url="apiUrl" ref="contactListDatatable"/>
            
            <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
            <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :operationId="operationId" />
            <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :id="contactlistId" :idType="'contactlist_id'" :key="'stat_'+statsKey"/>
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" @customRefresh="customRefresh" />
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
import importContactComponent from '@/components/importContactComponent'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";
import getForm from '@/mixins/mixins';

export default {
    name:"ContactsListList",
    mixins: [getForm],
    components: {
        DatatableV2, 
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
        importContactComponent, 
        dialogStatsContactComponent, 
        LibraryDialogComponent,
    },
    data() {
        return {
            apiUrl: "/contactlist/list",
            displayUpload: false,
            operationId: null,
            openDialogStats: false,
            contactlistId: null,
            formLib: null,
            libType: "SEGMENT",
            showLibraryDialog: false,
            form: null,
            statsKey:0
        }
    },
    methods: {
        importContacts(){
            this.displayUpload = true;
        },
        closeModal(){
            this.displayUpload = false;
        },
        launchSegmentation(contactlist) {
            if(!contactlist.emptyList)
                this.getForm("contactlist/" + contactlist.id + "/getForm?formName=segment", false, { event: 'customRefresh' });
            this.$refs.contactListDatatable.readDataFromAPI()
        },
        openStats(item){
            this.contactlistId = item.id;
            this.statsKey++
            this.openDialogStats = true;
        },
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
        customRefresh() {
            this.$refs.contactListDatatable.readDataFromAPI();
        }
    },
}
</script>