<template>
  <div class="wrapper">
    <div class="contentBrand">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
    </div>
  </div>
</template>
<script>


export default {
  name:"Brand",
  components: {
    
  },
  
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  computed: {
    
  },
  mounted() {
  },
  
}
</script>
