<template>
    <div>
        <v-container :style="'overflow: auto; height: calc(100vh - 225px)'" fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl" @showScript="showScript" />
        </v-container>

        <v-dialog v-model="openDialogScript" width="1200" :scrollable="true">
            <v-card class="lightgrey stats-contact">
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('websiteScriptTitle')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined small class="mr-2" @click="openDialogScript = false">{{$t('back')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-5 px-12">
                    <v-textarea :value="script" filled auto-grow hide-details readonly>{{ script }}</v-textarea>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'

export default {
    name: "WebsiteList",
    components: {
        DatatableV2
    },
    data() {
        return {
            apiUrl:"/website/list",
            openDialogScript: false,
            script: null
        };
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        showScript(websiteId) {
            GenericDataService.getData('/website/' + websiteId + '/getScript').then((response) => {
                this.script = response.data.data;
                this.openDialogScript = true;
            });
        }
    },
};
</script>
