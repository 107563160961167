<template>
  <div>
    <v-container :style="'overflow: auto; height: calc(100vh - 225px)'" fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" :flat="false" :url="apiUrl"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'

export default {
  name: "BrandList",
  components: {
    DatatableV2
  },
  data() {
    return {
      apiUrl:"/brand/list",
    };
  },
  mounted() {


  },
  computed: {
    // tableData: function(){
    //   return this.$store.state.contact.contacts;
    // }
  },
  methods: {
    //Méthodes dans Mixins : appel de l'api getForm, ouverture du form et refreshData
  },
};
</script>
