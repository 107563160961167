<template>
    <v-dialog scrollable class="rounded-lg" persistent fullscreen v-model="displayUpload">
        <v-card>
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-btn depressed color="transparent" class="square mr-2" v-if="stepper > 1" @click.native="goBack"><v-icon dark small>$prev</v-icon></v-btn>
                        <v-toolbar-title class="d-flex align-center">{{ $t(titles[stepper - 1]) }}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click.native="cancelUpload">{{ $t("cancel") }}</v-btn>
                        <v-btn outlined dark depressed v-if="stepper > 1" @click="nextTab" class="ml-3">{{ $t("next") }}</v-btn>
                    </div>
                </div> 
            </v-toolbar>

            <v-card-text class="pa-0">
                <v-stepper v-model="stepper" flat tile alt-labels class="no-shadow" style="height:100%; min-height:100%;">
                    <v-stepper-header class="no-shadow headerStepper" style="height:100px;">
                        <v-stepper-step :complete="stepper > 1" step="1">{{ $t(titles[0]) }}</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="stepper > 2" step="2" class="text-center">{{ $t(titles[1]) }}</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="stepper > 3" step="3" class="text-center">{{ $t(titles[2]) }}</v-stepper-step>
                    </v-stepper-header>
                
                    <v-stepper-items style="height: calc( 100% - 100px) !important;">
                        <v-stepper-content step="1" class="pt-0" style="height:100%;">
                            <v-card class="mb-12 no-shadow rounded-lg no-shadow" color="grey lighten-4" height="100%" style="height:100%;" flat tile>
                                <v-card-text
                                  class="pa-0 cardUpload"
                                  @drop.prevent="onDrop($event)"
                                  @dragenter.prevent="dragover = true"
                                  @dragover.prevent="dragover = true"
                                  @dragleave.prevent="dragover = false">
                                    <div class="uploadContent">
                                        <div>
                                            <v-icon x-large :color="fileToUpload.name ? 'success' : ''" class="cloudIcon mb-2">$cloud_s</v-icon>
                                            <v-progress-circular v-if="loading" :size="50" :width="7" color="success" class="ml-4" indeterminate ></v-progress-circular>
                                        </div>
                                        <h1 v-if="fileToUpload.name" class="success--text">Fichier sélectionné : {{fileToUpload.name}}</h1>
                                   
                                        <h1>Déposer votre fichier ici</h1>
                                        <p>ou</p>
                                        <v-btn depressed color="primary" @click="selectFile">Choisir un fichier</v-btn>
                                        <input ref="uploader" class="d-none" type="file" accept=".xlsx, .xls, .csv, .ods, .gz, .gzip" @change="onFileChange">
                                        <p class="mt-4 mb-0">Formats autorisés: CSV, XLS, XLSX, GZ, GZIP</p>
                                        <p class="mb-0">Taille maximum : 5Mo</p>
                                        <p class="mb-0">Restrictions: sans mot de passe, aucun filtres, uniquement 1 onglet</p>
                                        <p class="error--text errorFile mt-2" v-if="errorFile != ''">{{$t(errorFile)}}</p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content step="2" class="pt-0">
                            <div>
                                <v-alert color="primary" text border="left" icon="$info_s">
                                    <template v-slot:prepend>
                                        <v-icon  color="primary" class="ml-2 mr-4">
                                            $info_s
                                        </v-icon>
                                    </template>
                                    {{ $t("blacklist_step_2_info") }}
                                </v-alert>

                               <v-divider class="pb-2"></v-divider>

                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead class="tableHead">
                                            <tr>
                                                <th class="text-left">
                                                    Extrait du fichier
                                                </th>
                                                <th class="text-left">
                                                    Champ correspondant
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in extract" :key="index" class="trValues">
                                                <td class="exampleValues">
                                                    <b>{{ index }}</b>
                                                    <p v-for="exemple in extract[index]" :key="exemple">{{ exemple }}</p>
                                                </td>

                                                <td class="selectValues input-form">
                                                    <div class="selectContent">
                                                        <v-autocomplete dense solo flat v-model="mapping[index]" clearable placeholder="Choix du champ (non importé si non sélectionné)" :items="select" :no-data-text="$t('no-data-text')" @change="modifyList">
                                                            <template v-slot:item="{ item, attrs, on }">
                                                                <v-list-item v-on="on" v-bind="attrs" v-show="item.display || item.value == mapping[index]">
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>
                                                                            {{ item.text }}
                                                                        </v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </template>
                                                        </v-autocomplete>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content step="3" class="pt-0">
                            <div>
                                <v-alert color="primary" text border="left" icon="$info_s">
                                    <template v-slot:prepend>
                                        <v-icon  color="primary" class="ml-2 mr-4">
                                            $info_s
                                        </v-icon>
                                    </template>
                                    {{ $t("blacklist_step_3_info") }}
                                </v-alert>

                                <v-divider class="pb-2"></v-divider>
                            
                                <v-row dense>
                                    <v-col cols="10" class="reportContent pa-0 mt-1">
                                        <v-tabs class="reportTabs" v-model="toRemapTab" ref="tabs">
                                            <v-tab v-for="(item, index) in toRemap" :key="index" class="tabheader py-4 text-left" :ref="'tab' + index">
                                                <h2>{{ $t(index) }}</h2>
                                                <div class="example"><span class="coloredNb">{{ item.nb }}</span> Blacklist</div>
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="toRemapTab" class="pa-4 tabItem">
                                            <v-tab-item v-for="(item, index) in toRemap" :key="index">
                                                <v-simple-table>
                                                    <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                                <th>{{ $t(index) }}</th>
                                                                <th class="text-center">{{ $t("number_of_occurrences") }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(content, contentIndex) in item.values" :key="content.text_value">
                                                                <td>{{ content.address }}</td>
                                                                <td class="text-center">{{ content.nb_occurrences }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-col>

                                    <v-col cols="2" class="pt-0 pl-4 py-8 genericReport">
                                        <div class="numberReport" v-if="toRemap.emails">
                                            <v-icon large color="primary" class="mb-1">$at_l</v-icon>
                                            <div class="number">{{ toRemap.emails.nb }}</div>
                                            <span class="text text-center">{{ $t('emailBlacklisted') }}</span>
                                            <span v-if="toRemap.emails.nb_badly_formatted || toRemap.emails.nb_already_exist">
                                                <span v-if="toRemap.emails.nb_badly_formatted" class="red--text">{{ toRemap.emails.nb_badly_formatted }} {{ $t('badly_formatted_emails') }}</span>
                                                <span v-if="toRemap.emails.nb_badly_formatted && toRemap.emails.nb_already_exist"> / </span>
                                                <span v-if="toRemap.emails.nb_already_exist">{{ toRemap.emails.nb_already_exist }} {{ $t('already_exist_emails') }}</span>
                                            </span>
                                        </div>
                                        <div class="numberReport" v-if="toRemap.phones">
                                            <v-icon large color="primary" class="mb-1">$mobile_l</v-icon>
                                            <div class="number">{{ toRemap.phones.nb }}</div>
                                            <span class="text">{{ $t('phoneBlacklisted') }}</span>
                                            <span v-if="toRemap.phones.nb_badly_formatted || toRemap.phones.nb_already_exist">
                                                <span v-if="toRemap.phones.nb_badly_formatted" class="red--text">{{ toRemap.phones.nb_badly_formatted }} {{ $t('badly_formatted_phones') }}</span>
                                                <span v-if="toRemap.phones.nb_badly_formatted && toRemap.phones.nb_already_exist"> / </span>
                                                <span v-if="toRemap.phones.nb_already_exist">{{ toRemap.phones.nb_already_exist }} {{ $t('already_exist_phones') }}</span>
                                            </span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>

        <Loader v-if="showLoader"></Loader>
    </v-dialog>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import vCustomSelect from '@/components/forms/inputs/v-custom-select';
import Loader from '@/components/Loader.vue';

export default {
    name: "importBlacklistComponent",
    props: ["displayUpload"],
    components: { vCustomSelect, Loader },
    data() {
        return {
            titles: ['fileImportContactTitle', 'columnsConnectionTitle', 'validationTitle'],
            title: "IMPORT DU FICHIER",
            stepper: 1,
            dragover: false,
            toRemapTab: 0,
            errorFile: "",
            loading: false,
            fileToUpload: {},
            toRemap:{},
            select: [],
            extract: {},
            mapping: {},
            token: "",
            showLoader: false,
        }
    },
    mounted() {
        if(this.$refs.tab0){
            const initial = this.$refs.tab0[0].$el.offsetWidth;
            this.interval = setInterval(() => {
                if (this.$refs.tab0[0]) {
                    if (this.$refs.tab0[0].$el.offsetWidth !== initial) {
                        clearInterval(this.interval)
                        this.$refs.tabs.callSlider()
                    }
                } else {
                    clearInterval(this.interval)
                }
            }, 1000);
        } else {
            clearInterval(this.interval);
        }
    },
    watch: {
        displayUpload(val) {
            if(!val){
                this.fileToUpload = {}
                this.loading = false
            }
        },
    },  
    methods: {
        modifyList() {
            this.select.forEach(element => {
                element.display = true;

                if(Object.values(this.mapping).includes(element.value))
                    element.display = false;
            });
        },
        onDrop(e) {
            this.dragover = false;
            this.errorFile = "";
            this.fileToUpload = {};
            if (e.dataTransfer.files.length == 1) {
                e.dataTransfer.files.forEach(element => {
                    this.uploadFileForMapping(element);
                });
            }
        },
        onFileChange() {
            this.errorFile = "";
            this.fileToUpload = {};
            let file = this.$refs.uploader.files[0];
            if (!file)
                return;
            this.uploadFileForMapping(file);
        },
        uploadFileForMapping(file) {
            if(file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                && file.type != "application/vnd.ms-excel"
                && file.type != 'text/csv'
                && file.type != 'text/plain'
                && file.type != 'text/tab-separated-values'
                && file.type != 'application/gzip'
                && file.type != 'application/x-gzip'
                && file.type != 'application/vnd.oasis.opendocument.spreadsheet') {
                this.errorFile = 'wrongFormatError'
            } else if(file.size > (5 * 1024 * 1024)) {
                this.errorFile = 'fileTooBigError'
            } else {
                this.loading = true
                this.fileToUpload = file;
                const reader = new FileReader
                reader.onload = e => {
                    let jsonData = {
                        'name': this.fileToUpload.name,
                        'base64': e.target.result
                    };
                    GenericDataService.postData("/fileimport/upload", jsonData).then((response) => {
                        if(response.data.data.libTypes.includes('EMAIL'))
                            this.select.push({ "value": "email", "text": "Email", "display": true });
                        if(['SMS', 'MMS', 'VMS', 'TEL'].filter(value => response.data.data.libTypes.includes(value)).length)
                            this.select.push({ "value": "phone", "text": "Téléphone", "display": true });

                        this.extract = response.data.data.extract;
                        this.modifyList();
                        this.token = response.data.data.token
                        this.loading = false;
                        this.stepper++;
                        this.showLoader = false;
                    });
                }
                reader.readAsDataURL(this.fileToUpload)
            }
        },
        nextTab() {
            this.showLoader = true;
            if(this.stepper == 2) {
                this.processMapping();
            } else if(this.stepper == 3) {
                this.remapFile()
            } else {
                this.stepper++;
                this.showLoader = false;
            }
        },
        remapFile() {
            let payload = {
                token: this.token,
                toRemap: this.toRemap,
            }

            GenericDataService.postData("/fileimport/remapForBlacklist", payload).then((response) => {
                this.showLoader = false;
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK"); });
                this.stepper = 1;
                this.dragover = false
                this.fileToUpload = {}
                this.errorFile = ""
                this.toRemap = {}
                this.extract = {}
                this.mapping = {}
                this.token = "",
                this.$refs.uploader.value = null
                this.$emit('closeModal', true)
            }, (reject) => {
                this.showLoader = false;
            })
        },
        processMapping(){
            let mappingToSend = {};
            for(const property in this.mapping){
                if(this.mapping[property] !== false){
                    mappingToSend[property] = this.mapping[property]
                }
            }
            let payload = {
                token: this.token,
                mapping: mappingToSend
            }
            GenericDataService.postData("/fileimport/mapForBlacklist", payload).then((response) => {
                this.toRemap = response.data.data.toRemap;
                this.stepper++;
                this.showLoader = false;
            }, (reject) => {
                this.showLoader = false;
            });
        },
        goBack() {
            this.stepper--;
            this.showLoader = false;
        },
        selectFile() {
            this.$refs.uploader.click()
        },
        cancelUpload() {
            this.stepper = 1;
            this.showLoader = false;
            this.dragover = false
            this.fileToUpload = {}
            this.errorFile = ""
            this.toRemap = {}
            this.extract = {}
            this.mapping = {}
            this.select = []
            this.token = "",
            this.$refs.uploader.value = null
            this.$emit('closeModal', false)
        }
    },
}
</script>
<style lang="scss">
    .loader {
        position: absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index:2000;
    }
    .headerStepper {
        .v-stepper__label {
            text-align: center;
        }
    }
    .v-stepper__items {
        overflow:auto !important;
    }
    .v-stepper__content {
        min-height:100% !important;
    }
    .v-stepper__wrapper {
        height:100% !important;
    }
    .cardUpload {
        border: 2px dashed #999;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        .uploadContent {
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .cloudIcon {
                font-size: 70px !important;
                height: 70px !important;
                width: 70px !important;
                svg {
                    font-size: 70px !important;
                    height: 70px !important;
                    width: 70px !important;
                }
            }
            h1 {
                font-size:20px;
            }
             .errorFile {
                 font-size:18px;
                color:red;
            }
        }
    }
    .reportContent {
        h2 {
            min-height: 18px;
        }
        tr {
            td {
                height:45px !important;
                font-size:13px !important;
            }
        }
    }
    .exampleValues {
        b {
            margin-bottom:4px !important;
        }
        p {
            margin-bottom: 0 !important;
            font-size: 12px !important;
            line-height: 15px !important;
        }
    }
    .trValues {
        margin-top:20px !important;
        height:75px;
    }
    .selectValues {
        .selectContent {
            display:flex;
            align-items: center;
        }
        
    }
    .reportTabs {
        .v-tabs-bar {
             height:unset !important;
            //min-height: 110px;
        }
        .v-tabs-bar__content {
            min-height:40px;
            height: unset !important;
        }
        .v-tabs-slider-wrapper {
            bottom:unset !important;
            top:0px !important;
        }
        .tabheader {
            flex-direction: column;
            align-items:flex-start;
            justify-content: space-between;
            width:100%;
            border: 1px solid #DDD;
            text-transform: unset;
            border-right-width: 0px;
            border-bottom-width: 0px;
            &:last-child{
                border-right-width: 1px;
            }
            background-color: #EEE !important;
            &.v-tab--active{
                background-color: #FFF !important;
            }
            .example {
                color: rgba(0, 0, 0, 0.54)
            }
            .coloredNb {
                 color: var(--v-primary-base) !important;
                 margin-right:5px;
            }
        }
    }
    .tabItem {
        .v-tabs-items {
            border: 1px solid #DDD;
            .radio-item {
                //display: block;
                position: relative;
                padding: 0 6px;
                margin: 0;
                height:20px;
            }

            .radio-item input[type="radio"] {
                display: none;
            }

            .radio-item label {
                color: #666;
                font-weight: normal;
                height: 20px;
            }

            .radio-item label:before {
                content: " ";
                display: inline-block;
                position: relative;
                top: 0px;
                margin: 0 ;
                width: 20px;
                height: 20px;
                border-radius: 11px;
                border: 2px solid #004c97;
                background-color: transparent;
            }

            .radio-item input[type="radio"]:checked + label:after {
                border-radius: 11px;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 4px;
                left: 10px;
                content: " ";
                display: block;
                background: #004c97;
            }
        }
    }
    
    .tableHead {
        tr {
            th {
                border-bottom-width:2px !important;
            }
        }
    }
    .genericReport {
        a {
            text-decoration:underline;        
        }
        .numberReport {
            height:150px;
            border: 1px solid #DDD;
            border-bottom-width:0px;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .number {
                font-size:32px;
                line-height: normal;
                font-weight: bold;
            }
            .text {
                text-decoration: uppercase;
            }
            &:last-child {
                border-bottom-width:1px;
            }
            &.first {
                color:white;
                background-color: var(--v-primary-base) !important;
            }
            
        }
    }
    .v-expansion-panels.active {
        .v-expansion-panel {
            .v-expansion-panel-header {
                border-radius:0;
                background-color: var(--v-lightgrey-base) !important;
                border-radius:5px;

            }
        }
    }
</style>