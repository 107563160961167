<template>
  <div class="wrapper" style="height:100%;">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>
export default {
  name: "Administration",
  data() {
    return {
     
    };
  },
  beforeRouteLeave (to, from, next) {
      this.$store.dispatch('base/SET_SUB_MENU', []);
      this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
      this.operationToDisplay = {};
      next();
  },
  methods: {
  },
  computed: {
    
  },
  mounted() {
  },
};
</script>
