<template>
    <div v-if="data && colors">
        <v-btn dense depressed :style="btnStyle">Bouton</v-btn>
    </div>
</template>
<script>
export default {
    name: "btnPreview",
    props: ["colors", "data"],
    computed: {
        btnStyle(){
            let css = ""
            if(this.data.style == 'outlined'){
                
                 
                let color1 = this.colors[this.data.color1]
                switch (this.data.brightness_color1) {
                    case 'darker':
                        color1 = this.shadeColor(color1, -20)
                        break;
                    case 'lighter':
                        color1 = this.shadeColor(color1, 20)
                        break;
                }
                css += "color: "+color1+"; "
                css += "border: 1px solid "+color1+"; "

                
                css += "background-color: transparent;"
            } else {
                css += "border: none; "

                let color2 = this.colors[this.data.color2]
                switch (this.data.brightness_color2) {
                    case 'darker':
                        color2 = this.shadeColor(color2, -30)
                        break;
                    case 'lighter':
                        color2 = this.shadeColor(color2, 30)
                        break;
                }

                css += "color: "+color2+"; "

                let color1 = this.colors[this.data.color1]
                switch (this.data.brightness_color1) {
                    case 'darker':
                        color1 = this.shadeColor(color1, -30)
                        break;
                    case 'lighter':
                        color1 = this.shadeColor(color1, 30)
                        break;
                }
                
                //css += "color: "+color1+"; "
                css += "background-color: "+color1+";"
            }
            switch (this.data.rounded) {
                case 'soft':
                    css += "border-radius: 4px; "
                    break;
                case 'large':
                    css += "border-radius: 10px; "
                    break;
                default:
                    css += "border-radius: 0px; "
                    break;
            }
            css += "text-transform: "+this.data.case+"; "
            css += "font-family:"+this.data.weight+"_police;"
            
            return css
        }
    },
    methods: {
        shadeColor(color, percent) {
            var R = parseInt(color.substring(1,3),16);
            var G = parseInt(color.substring(3,5),16);
            var B = parseInt(color.substring(5,7),16);

            R = parseInt(R * (100 + percent) / 100);
            G = parseInt(G * (100 + percent) / 100);
            B = parseInt(B * (100 + percent) / 100);

            R = (R<255)?R:255;  
            G = (G<255)?G:255;  
            B = (B<255)?B:255;  

            var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
            var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
            var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

            return "#"+RR+GG+BB;
        }
    },
}
</script>