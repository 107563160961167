<template>
  <div>
    <v-container fluid class="pa-6 ">
      <DatatableV2 :displayTitle="false" @importContacts="importContacts" @openStats="openStats" :flat="false" @openExportUnsubscribe="exportunsubscribe" :url="apiUrl"/>
      <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :operationId="operationId" />
      <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" />
      <exportUnsubscribeContacts :displayUnsubscribe="displayExportUnsubscribe" @closeUnsubscribe="displayExportUnsubscribe= false"/>
    </v-container>
  </div>
</template>

<script>
import DatatableV2 from '@/components/DatatableV2';
import importContactComponent from '@/components/importContactComponent'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'
import exportUnsubscribeContacts from '../../../components/exportUnsubscribeContacts.vue';

export default {
  name: "ContactsList",
  components: {
    DatatableV2,
    importContactComponent,
    dialogStatsContactComponent,
    exportUnsubscribeContacts
  },
  data() {
    return {
      apiUrl:"",
      displayUpload: false,
      openDialogStats: false,
      displayExportUnsubscribe: false,
      operationId: null
    };
  },
  mounted() {
    if(this.$store.state.auth.user.is_presta)
        this.apiUrl = "/mission/getContacts";
    else
        this.apiUrl = "/contact/list";
  },
  computed: {},
  methods: {
    //Méthodes dans Mixins : appel de l'api getForm, ouverture du form et refreshData
    exportunsubscribe(){
      this.displayExportUnsubscribe= true
    },
    importContacts(){
      this.displayUpload = true;
    },
    openStats(){
      this.openDialogStats = true;
    },
    closeModal(){
      this.displayUpload = false;
    }
  },
};
</script>
