<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" :flat="false" @gotoWorkflow="gotoWorkflow" :url="apiUrl"/>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"OperationsTypeDetailWorkflows",
    components:{DatatableV2},
    data() {
        return {
            apiUrl:"/workflow/list?type_operation_id="+this.$route.params.operationtype_id,
        }
    },
    methods: {
        gotoWorkflow(id){
            this.$router.push({name: 'WorkflowOperationTypeDetail', params:{workflow_id: id}})
        },
    },
}
</script>