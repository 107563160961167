<template>
    <v-card>
        <v-card-title class="text-right">
            <h4 class="icon-title" style="width:100%; font-size:20px;  border-bottom: none;">
                {{$t('editStyleTitle')}}
                <div>
                    <v-btn small depressed color="primary" @click="recordBrandStyle" class="mr-3">{{$t('recordLabel')}}</v-btn>
                    <v-btn small depressed color="grey" class="white--text mr-3" @click="resetSection(tabModel)">{{$t('resetSectionLabel')}}</v-btn>
                    <v-btn small depressed color="error" @click="resetSection()">{{$t('resetAllLabel')}}</v-btn>
                </div>
            </h4>
        </v-card-title>
        <v-card-text>
            <v-tabs vertical v-model="tabModel">
                <div class=" pa-3 lightgrey rounded-lg">
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 0 ? false : true" width="100%">{{$t('colorsTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 1 ? false : true" width="100%">{{$t('fontsTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 2 ? false : true" width="100%">{{$t('btnsTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 3 ? false : true" width="100%">{{$t('logoTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 4 ? false : true" width="100%">{{$t('BackgroundContentTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 5 ? false : true" width="100%">{{$t('BackgroundHeaderTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 6 ? false : true" width="100%">{{$t('CutOutImageTitle')}}</v-btn>
                    </v-tab>
                    <v-tab class="px-0 mb-2" style="height:auto;">
                        <v-btn color="primary" depressed small :text="tabModel == 7 ? false : true" width="100%">{{$t('FaviconTitle')}}</v-btn>
                    </v-tab>
                </div>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text>
                            <div v-if="css && css.colors" style="display:flex; flex-wrap:wrap; justify-content:space-between;">
                                <div v-for="(inputColor, color) in css.colors" :key="color" class="mb-4" style="width:49%;">
                                    <colorPicker v-model="css.colors[color]" :label="$t(color)" />
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                    <v-card-text v-if="brand && brand.libelle">
                        <div v-for="(inputFont, fontName) in css.fonts" :key="fontName" class="mb-4" style="width:49%;">
                            <p class="mb-1">{{$t(fontName+'FontLabel')}}</p>
                            <vFileInputDirect @change="changePolice" :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['fonts']}, accept:'.woff, .woff2, .ttf'}}" :model="css.fonts" :fieldName="fontName"/>
                        </div>
                    </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                    <v-card-text v-if="css && css.btns">
                        <div v-for="(btn, index) in css.btns" :key="'btn_'+index">
                            <h4 class="mb-2">
                                <b>{{$t(index+"Label")}}</b>
                                <p class="mb-0" style="text-transform:none">{{$t(index+"Explanation")}}</p>
                            </h4>
                            <v-container fluid class="mb-4">
                                <v-row dense>
                                    <v-col cols="4">
                                        <v-select filled dense hide-details tile :items="selectFontStyle" class="mb-2" :label="$t('btnStyleLabel')" v-model="btn.style" :no-data-text="$t('no-data-text')"></v-select>
                                        <v-select filled dense hide-details tile :items="selectColor" v-model="btn.color1" :label="$t('btnColor1Label')" class="mb-2" :no-data-text="$t('no-data-text')">
                                            <template v-slot:selection="{ item }">
                                            <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                                    <v-icon icon :color="item.color">
                                                        $square_s
                                                    </v-icon>
                                                    <p class="mb-0 ml-2">{{item.text}}</p>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                                    <v-icon icon :color="item.color">
                                                        $square_s
                                                    </v-icon>
                                                    <p class="mb-0 ml-2">{{item.text}}</p>
                                                </div>
                                            </template>
                                        </v-select>
                                        <v-select filled dense hide-details tile :items="selectBrightnessStyle" :label="$t('btnBrightnessColor1Label')" class="mb-2" v-model="btn.brightness_color1" :no-data-text="$t('no-data-text')"></v-select>


                                        <v-select filled dense hide-details tile v-if="btn.style != 'outlined'" :items="selectColor" :label="$t('btnColor2Label')" v-model="btn.color2" class="mb-2" :no-data-text="$t('no-data-text')">
                                            <template v-slot:selection="{ item }">
                                            <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                                    <v-icon icon :color="item.color">
                                                        $square_s
                                                    </v-icon>
                                                    <p class="mb-0 ml-2">{{item.text}}</p>
                                                </div>
                                            </template>
                                            <template v-slot:item="{ item }">
                                                <div style="display:flex; align-items:center; height:100%; position:absolute;">
                                                    <v-icon icon :color="item.color">
                                                        $square_s
                                                    </v-icon>
                                                    <p class="mb-0 ml-2">{{item.text}}</p>
                                                </div>
                                            </template>
                                        </v-select>
                                        <v-select filled dense hide-details tile v-if="btn.style != 'outlined'" :items="selectBrightnessStyle" :label="$t('btnBrightnessColor2Label')" class="mb-2" v-model="btn.brightness_color2" :no-data-text="$t('no-data-text')"></v-select>
                                        <v-select filled dense hide-details tile :items="selectBtnRounded" :label="$t('btnRoundedLabel')" class="mb-2" v-model="btn.rounded" :no-data-text="$t('no-data-text')"></v-select>
                                        <v-select filled dense hide-details tile :items="selectBtnCase" :label="$t('btnCaseLabel')" class="mb-2" v-model="btn.case" :no-data-text="$t('no-data-text')"></v-select>
                                        <v-select filled dense hide-details tile :items="selectFontWeight" :label="$t('btnWeightLabel')" v-model="btn.weight" :no-data-text="$t('no-data-text')"></v-select>
                                    </v-col>
                                    <v-col cols="8">
                                        <div class="btnPreview">
                                            <btnPreview :data="btn" :colors="css.colors" />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text v-if="brand && brand.libelle">
                            <div v-for="(inputLogo, logoType) in css.logos" :key="logoType" class="mb-4" style="width:49%;">
                                <p class="mb-1">{{$t(logoType+'LogoLabel')}}</p>
                                <vFileInputDirect :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['logo']}}}" :model="css.logos" :fieldName="logoType"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text v-if="brand && brand.libelle">
                            <div class="mb-4" style="width:49%;">
                                <p class="mb-1">{{$t('BackgroundContentLabel')}}</p>
                                <vFileInputDirect :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['background']}}}" :model="css" fieldName="background_content"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text v-if="brand && brand.libelle">
                            <div class="mb-4" style="width:49%;">
                                <p class="mb-1">{{$t('BackgroundHeaderLabel')}}</p>
                                <vFileInputDirect :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['header']}}}" :model="css" fieldName="background_header"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text v-if="brand && brand.libelle">
                            <div class="mb-4" style="width:49%;">
                                <p class="mb-1">{{$t('CutOutImageLabel')}}</p>
                                <vFileInputDirect :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['cutout']}}}" :model="css" fieldName="cutout_image"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="no-shadow">
                        <v-card-text v-if="brand && brand.libelle">
                            <div class="mb-4" style="width:49%;">
                                <p class="mb-1">{{$t('FaviconLabel')}}</p>
                                <vFileInputDirect :field="{props:{meta:{brand:[brand.libelle, brand.slug], type:['favicon']}}}" :model="css" fieldName="favicon"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                </v-tabs>
        </v-card-text>
    </v-card>
</template>
<script>
import colorPicker from '@/components/colorPicker'
import vFileInputDirect from '@/components/forms/inputs/v-file-input-direct'
import btnPreview from '@/components/btnPreview'
import GenericDataService from '@/services/GenericDataService'
import refreshData from "@/mixins/mixins";

export default {
    name:"styleBrandComponent",
    mixins: [refreshData],
    components:{colorPicker, vFileInputDirect, btnPreview},
    data() {
        return {
            tabModel:0,
            apiUrl: "",
            mutation: "",
            selectBtnRounded: [
                {
                    text: "Sans",
                    value: "none",
                },
                {
                    text: "Léger",
                    value: "soft",
                },
                {
                    text: "Grand",
                    value: "large",
                },
            ],
            selectBtnCase: [
                {
                    text: "Normal",
                    value: "none",
                },
                {
                    text: "Capitales",
                    value: "uppercase",
                },
                {
                    text: "Minuscules",
                    value: "lowercase",
                },
            ],
            selectFontStyle: [
                {
                    text: "Classique",
                    value: "classic",
                },
                {
                    text: "Outlined",
                    value: "outlined",
                },
            ],
            selectBrightnessStyle: [
                {
                    text: "Plus sombre",
                    value: "darker",
                },
                {
                    text: "Plus clair",
                    value: "lighter",
                },
                {
                    text: "Normal",
                    value: "normal",
                },
            ],
                  
        }
    },
    created() {
        if(this.css && this.css.fonts && this.css.fonts.light && this.css.fonts.bold && this.css.fonts.black && this.css.fonts.regular){
            var newStyle = document.createElement('style');
            newStyle.id = "fontStyle"
            if(this.css.fonts.black && this.css.fonts.black.length > 0){
                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'black_police';\
                    src: url('" + this.css.fonts.black[0].url + "');\
                }\
                "));
            }
            
            if(this.css.fonts.bold && this.css.fonts.bold.length > 0){
                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'bold_police';\
                    src: url('" + this.css.fonts.bold[0].url + "');\
                }\
                "));  
            }
            
            if(this.css.fonts.regular && this.css.fonts.regular.length > 0){
                newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'regular_police';\
                    src: url('" + this.css.fonts.regular[0].url + "');\
                }\
                "));
            }
            
            if(this.css.fonts.light && this.css.fonts.light.length > 0){
                newStyle.appendChild(document.createTextNode("\
                    @font-face {\
                        font-family: 'light_police';\
                        src: url('" + this.css.fonts.light[0].url + "');\
                    }\
                    "));
            }
            

            document.head.appendChild(newStyle);

        }
        
    },
    methods: {
        resetSection(tab = null){
            let tabName;
            switch (tab) {
                case 0:
                    tabName = "colors"
                    break;
                case 1:
                    tabName = "fonts"
                    break;
                case 2:
                    tabName = "btns"
                    break;
                case 3:
                    tabName = "logos"
                    break;
                case 4:
                    tabName = "images"
                    break;
                default:
                    tabName = null
                    break;
            }
            let account_id;
            if(this.brand.parent_id == null){
                account_id = this.brand.account_id
            } else {
                account_id = 0
            }
            GenericDataService.getData("/brand/getByName?name="+this.brand.libelle+"&account_id="+account_id).then((response) => {
                let partToReplace = ""
                if(!tabName){
                    partToReplace = response.data.data.styleConf
                } else {
                    partToReplace = response.data.data.styleConf[tabName]
                }
                this.$store.dispatch('brand/RESET_DATA', {section: tabName, value: partToReplace})
                this.changePolice()
                this.$forceUpdate()
            })
            
        },
        recordBrandStyle(){
            let brandData = this._.cloneDeep(this.brand)
            brandData.styleConf = this.css;
            GenericDataService.postData('/brand/set', brandData).then((response) => {
                let id = response.data.data.id
                let routeId = this.$route.params.brand_id
                if(routeId != id){
                    this.apiUrl = "/brand/"+id+"/get",
                    this.mutation = "brand/setBrandData",
                    this.refreshData();
                    this.$router.push({name: 'BrandDashboard', params:{ brand_id: id }})
                }
                this.$store.dispatch('ADD_SNACK', {
                    message : this.$t('successActionConfirmed'),
                }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                })
            })
        },
        changePolice(){
            let element = document.getElementById('fontStyle')
            if(element){
                if (element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            }
            var newStyle = document.createElement('style');
            newStyle.id = "fontStyle"
            newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'black_police';\
                    src: url('" + this.css.fonts.black[0].url + "');\
                }\
            "));
            newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'bold_police';\
                    src: url('" + this.css.fonts.bold[0].url + "');\
                }\
            "));
            newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'regular_police';\
                    src: url('" + this.css.fonts.regular[0].url + "');\
                }\
            "));
            newStyle.appendChild(document.createTextNode("\
                @font-face {\
                    font-family: 'light_police';\
                    src: url('" + this.css.fonts.light[0].url + "');\
                }\
            "));
            document.head.appendChild(newStyle);
        
           
        }
    },
    watch: {
        tabModel:{
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler(val){
                if(val == 2){
                    for (const fontName in this.css.fonts) {
                        const font = this.css.fonts[fontName];
                        if(font.length == 0){
                            GenericDataService.getData('/brand/getDefaultStyle').then((response) => {
                                let default_font = response.data.data.fonts[fontName][0]
                                font.push(default_font)
                                this.changePolice();
                            })
                            
                        }
                    }
                }
            }
            
        }
    },
    computed: {
        brand(){
            return this.$store.state.brand.brand
        },
        css() {
            return this.$store.state.brand.brand.styleConf
        },
        selectColor(){
            let colors = []
            for (const colorName in this.css.colors) {
                    const element = this.css.colors[colorName];
                    const colorObject = {
                        text: colorName,
                        value : colorName, 
                        color: element
                    }
                    colors.push(colorObject)
            }

            return colors
        },
        selectFontWeight(){
            let fontWeights = []
            for (const weightName in this.css.fonts) {
                fontWeights.push(weightName)
            }

            return fontWeights
        }
    }, 
}
</script>
<style lang="scss">
    .editStyle {
        .v-tabs-slider-wrapper {
            display: none;
        }
        .btnPreview {
            margin-left:20px;
            border-radius:5px;
            display:flex;
            align-items: center;
            justify-content: center;
            min-height:100%;
            border: 1px solid rgba(0,0,0,0.1)
        }
    }
</style>