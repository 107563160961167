<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :displayTitle="false" @importContacts="importContacts" @openStats="openStats" :flat="false" :url="apiUrl" ref="contactListDatatable"/>
            <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" />
            <dialogStatsContactComponent v-if="openDialogStats" @closeStatsContactDialog="openDialogStats = false" :openDialogStats="openDialogStats" :id="fileId" :idType="'file_id'" />
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
import importContactComponent from '@/components/importContactComponent'
import dialogStatsContactComponent from '@/components/dialogs/dialogStatsContactComponent'

export default {
    name:"ContactsListList",
    components: {DatatableV2, importContactComponent, dialogStatsContactComponent},
    data() {
        return {
            apiUrl:"/fileimport/list",
            displayUpload: false,
            openDialogStats: false,
            fileId: null,
        }
    },
    methods: {
        //Méthodes dans Mixins : appel de l'api getForm, ouverture du form et refreshData
        importContacts(){
            this.displayUpload = true;
        },
        closeModal(){
            this.displayUpload = false;
        },
        openStats(item){
            this.fileId = item.id;
            this.openDialogStats = true;
        },
    },
}
</script>