<template>
    <v-container fluid class="pa-6">
        <div v-if="this.stats">
            <v-row dense>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <h3 class="my-6">{{stats.visits_summary.nb_visits_new + stats.visits_summary.nb_visits_returning}}</h3>
                        <span class="label mt-3 my-4">{{ $t('visits') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <h3 class="my-6">{{stats.visits_summary.nb_uniq_visitors_new}}</h3>
                        <span class="label mt-3 my-4">{{ $t('unique_visits') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <h3 class="my-6">{{stats.visits_summary.nb_users_new}}</h3>
                        <span class="label mt-3 my-4">{{ $t('known_visitors') }}</span>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="elementStat py-6 px-2" depressed rounded="lg" elevation="1">
                        <h3 class="my-6">{{stats.visits_summary.avg_time_on_site_new}} s</h3>
                        <span class="label mt-3 my-4">{{ $t('average_duration_of_a_visit') }}</span>
                    </v-card>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3">
                    <donutContactStatsComponent v-for="(graph, index) in donutGraphs" :data="graph" :filters="false" :key="'donutgraph_' + index" class="mb-2" :ref="'graph_' + graph.label" />
                </v-col>
                <v-col cols="9">
                    <barContactStatsComponent v-for="(graph, index) in barGraphs" v-if="graph.datas" :data="graph" :filters="false" class="mb-2" :key="'bargraph_' + index" />
                    <v-data-table class="mb-2" :headers="datatable.headers" :items="datatable.datas" :items-per-page="15"></v-data-table>
                </v-col>
            </v-row>
        </div>

        <Loader v-if="showLoader"></Loader>
    </v-container>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import barContactStatsComponent from '@/components/dialogs/dialogStatsComponents/barContactStatsComponent';
import donutContactStatsComponent from '@/components/dialogs/dialogStatsComponents/donutContactStatsComponent';
import Loader from '@/components/Loader.vue';

export default {
    name:"WebsiteDashboard",
    components: { barContactStatsComponent, donutContactStatsComponent, Loader },
    data() {
        return {
            stats: null,
            donutGraphs: [
                {
                    "label": "sources",
                    "datas": []
                },
                {
                    "label": "devices",
                    "datas": []
                },
                {
                    "label": "browsers",
                    "datas": []
                }
            ],
            barGraphs: [
                {
                    label: "last_visits_graph",
                    type: "LineComponent",
                    datas: {
                        labels: [],
                        datasets: [{
                            label: "visits",
                            fill: false,
                            borderColor: 'blue',
                            data: []
                        }]
                    }
                }
            ],
            datatable: {
                headers: [
                    { text: this.$t('page'), value: 'label' },
                    { text: this.$t('visits'), value: 'nb_visits' },
                    { text: this.$t('average_visit_time') + ' (s)', value: 'avg_time_on_page' },
                    { text: this.$t('bounce_rate'), value: 'bounce_rate' },
                    { text: this.$t('exit_rate'), value: 'exit_rate' },
                ],
                datas: []
            },
            showLoader: false,
        }
    },
    computed: {
        website(){
            return this.$store.state.website.website
        }
    },
    created() {
        this.showLoader = true;
        GenericDataService.getData('/website/' + this.$route.params.website_id + '/getStats').then((response) => {
            this.showLoader = false;
            this.stats = response.data.data;

            for(let referrer in this.stats.referrers)
                if(this.stats.referrers[referrer].nb_visits > 0)
                    this.donutGraphs[0].datas.push({ "value": this.stats.referrers[referrer].nb_visits, "label": this.stats.referrers[referrer].label, "notFilterable": true });
            for(let device in this.stats.devices)
                if(this.stats.devices[device].nb_visits > 0)
                    this.donutGraphs[1].datas.push({ "value": this.stats.devices[device].nb_visits, "label": this.stats.devices[device].label, "notFilterable": true });
            for(let browser in this.stats.browsers)
                if(this.stats.browsers[browser].nb_visits > 0)
                    this.donutGraphs[2].datas.push({ "value": this.stats.browsers[browser].nb_visits, "label": this.stats.browsers[browser].label, "notFilterable": true });

            for(let page in this.stats.pages)
                this.datatable.datas.push(this.stats.pages[page]);

            for(let date in this.stats.visits_stats) {
                let tmpDate = new Date(date);
                this.barGraphs[0].datas.labels.push(tmpDate.toLocaleDateString());
                this.barGraphs[0].datas.datasets[0].data.push(this.stats.visits_stats[date].nb_visits);
            }
        });
    }
}
</script>

<style lang="scss">
    .elementStat {
        width: 100%;
        text-align: center;
        h3 {
            color: inherit !important;
            font-weight: 800;
            font-size: 30px;
            @media(-webkit-device-pixel-ratio: 1.25) {
                font-size: 30px;
                margin-top: 10px;
            }
            span {
                font-size: 15px;
                font-weight: 400;
            }
        }
        .label {
            display: block;
            font-size: 13px;
        }
    }
</style>