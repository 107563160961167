<template>
    <div class="wrapper" style="height:100%;">
        <div class="contentWebsite" style="height:100%;">
            <v-expand-transition>
                <router-view />
            </v-expand-transition>
        </div>
    </div>
</template>

<script>
export default {
    name:"Library",
    components: {},
    data() {
        return {}
    },
    methods: {},
    computed: {},
    mounted() {},
}
</script>
